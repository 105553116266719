import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {
    authorizationSetSelectedDay,
    authorizationSetSelectedPerson
} from "../../store/authorization/authorizationAction";
import moment from 'moment-timezone';
import Meeting from "./Meeting";
import Sponsors from "./Sponsors";
import {Dropdown} from "react-bootstrap";
import {projectLoadSuccess} from "../../store/project/projectAction";
import ContactWidgetLink from "./ContactWidgetLink";
import ScrollToTop from "../../elements/helpers/ScrollToTop";
import {MeetingsLineXL, MeetingsLineLG, MeetingsLineMD, MeetingsLineXS, MeetingsIconExhibition, MeetingsLogo2, MeetingsIconNow} from "../../elements/helpers/GfxPaths";
import EigenkapitalTheme from "../../elements/helpers/EigenkapitalTheme";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import DayTimetable from "../../elements/pdf/DayTimetable";
import EquityTheme from "../../elements/helpers/EquityTheme";

class Meetings extends React.Component {

    state = {
        hoverDate: false
    };

    forceUpdateInterval = null;

    componentDidMount() {
        this.forceUpdateInterval = setInterval(() => {
            this.forceUpdate();
        }, 60000);
    }

    componentWillUnmount() {
        if(this.forceUpdateInterval) clearInterval(this.forceUpdateInterval);
    }

    render() {
        const {project, authorization} = this.props;
        let curPerson;
        let meetings = [];
        let projectDay = {};
        if (project.loaded) {
            curPerson = project.projectPersons.find((pp) => pp.personType === authorization.selectedPersonType && pp.personId === authorization.selectedPersonId);
            if (curPerson) {
                projectDay = project.projectDays.find((el) => el.id === authorization.selectedDay);
                meetings = curPerson.meetings.filter((meeting) => meeting.projectDayId === authorization.selectedDay).map((meeting) => ({
                    ...meeting,
                    startAt: moment(meeting.startAt),
                    realStartAt: moment(moment(projectDay.date).tz('Europe/Berlin').format('DD.MM.YYYY') + ' ' + moment(meeting.startAt).format('HH:mm'), 'DD.MM.YYYY HH:mm'),
                    realEndAt: moment(moment(projectDay.date).tz('Europe/Berlin').format('DD.MM.YYYY') + ' ' + moment(meeting.endAt).format('HH:mm'), 'DD.MM.YYYY HH:mm')
                }))
            }
        }
        return (
            <div className="page page-meetings">
                <ScrollToTop/>
                {project.loaded && projectDay && projectDay.date ? (
                    <Fragment>
                        <div className="day-selector-container">
                            <div className="container container-days">
                                <div className="row">
                                    <div className="col-6 d-flex justify-content-between justify-content-md-start">
                                        {project.projectDays.map((projectDay, pDI) => (
                                            <button
                                                className={`project-day${authorization.selectedDay === projectDay.id ? ' active' : ''}${curPerson.meetings.filter((meeting) => meeting.projectDayId === projectDay.id).length === 0 ? ' noappointments' : ''}`}
                                                onClick={() => {window.scrollTo(0, 0); this.props.authorizationSetSelectedDay(projectDay.id)}}
                                                onMouseEnter={() => {this.setState({hoverDate: true})}}
                                                onMouseLeave={() => {this.setState({hoverDate: false})}}
                                                key={pDI}>
                                                {moment(projectDay.date).tz('Europe/Berlin').isSame(moment().tz('Europe/Berlin'), 'day') ? (
                                                    <div className="imgNow"><img className="d-none d-lg-inline-block w-100" src={MeetingsIconNow} alt=""/></div>
                                                ) : null}
                                                <div className="dayName">{moment(projectDay.date).tz('Europe/Berlin').format('dddd')},</div>
                                                <div
                                                    className={`date ${this.state.hoverDate ? "noBorder" : ""}`}>{moment(projectDay.date).tz('Europe/Berlin').format('DD.MM.YYYY')}</div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="container container-widgets pl-0">
                                <div className="row">
                                    <div className="col-6 d-flex">
                                        <EigenkapitalTheme>
                                            <div className="border-fl flex-grow-1">
                                                <img alt="" src={MeetingsLineXL} className="d-none d-xl-block"/>
                                                <img alt="" src={MeetingsLineLG} className="d-none d-lg-block d-xl-none"/>
                                                <img alt="" src={MeetingsLineMD} className="d-none d-md-block d-lg-none"/>
                                                <img alt="" src={MeetingsLineXS} className="d-block d-md-none"/>
                                            </div>
                                            <div className="day-select-widgets d-flex justify-content-between align-items-end">
                                                <a className="research-widget-link d-flex" href="https://eigenkapitalforum.expo-ip.com/" target="_blank" rel="noopener noreferrer">
                                                    <div className="img-container">
                                                        <img src={MeetingsIconExhibition} alt=""/>
                                                    </div>
                                                </a>
                                                <ContactWidgetLink/>
                                            </div>
                                        </EigenkapitalTheme>
                                        <EquityTheme>
                                            <ContactWidgetLink/>
                                        </EquityTheme>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container page-meetings-content-container">
                            <div className="row">
                                <div className="col-6 col-md-3 col-lg-6 col-logo2">
                                    <EigenkapitalTheme>
                                        <a href="https://www.deutsche-boerse-cash-market.com/" target="_blank" rel="noopener noreferrer">
                                            <img className="logo2" src={MeetingsLogo2} alt=""/>
                                        </a>
                                    </EigenkapitalTheme>
                                </div>
                                <div className="col-6 col-md-3 col-lg-6">
                                    {project.loaded && curPerson && project.projectPersons.length > 1 ? (
                                        <div className="person-selector-container">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="default">
                                                    <div className="dropdown-toggle-content">
                                                        <strong>{curPerson.companyName}</strong><br/>
                                                        <i>{curPerson.personName}</i>
                                                    </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {project.projectPersons.filter((pp) => !(pp.personType === curPerson.personType && pp.personId === curPerson.personId)).map((projectPerson, ppI) => (
                                                        <Dropdown.Item
                                                            onClick={() => this.props.authorizationSetSelectedPerson(projectPerson.personType, projectPerson.personId)}
                                                            key={ppI}>
                                                            <strong>{projectPerson.companyName}</strong><br/>
                                                            <i>{projectPerson.personName}</i>
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-lg-4 col-meetings">
                                    {meetings.length > 0 ? meetings.sort((a, b) => a.realStartAt - b.realStartAt).map((meeting, mI) => (
                                        <Meeting person={curPerson} meeting={meeting}
                                                 nextMeeting={mI < (meetings.length - 1) ? meetings[mI + 1] : null}
                                                 key={`${mI}-${meeting.meetingId}`} isLast={mI === (meetings.length - 1)}/>
                                    )) : (
                                        <div className="no-meetings">
                                            You do not have any meetings in your calendar on {moment(projectDay.date).tz('Europe/Berlin').format('dddd')}.
                                        </div>
                                    )}
                                    <div className="text-center">
                                        <PDFDownloadLink document={<DayTimetable projectDay={projectDay} meetings={meetings} person={curPerson} projectInfo={project.projectInfo}/>} fileName="timetable.pdf" className="download-daytimetable">
                                            {({ blob, url, loading, error }) =>
                                              loading ? '' : 'Download timetable as PDF'
                                            }
                                        </PDFDownloadLink>
                                    </div>
                                </div>
                                <div className="col-6 col-lg-2 pl-lg-0 pl-xl-3 col-xl-2 col-sponsors">
                                    <Sponsors/>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    project: state.project,
    authorization: state.authorization
});

export default connect(mapStateToProps, {
    authorizationSetSelectedDay, projectLoadSuccess, authorizationSetSelectedPerson
})(Meetings);
